<template>
  <div class="navbar">
    <nav class="nav-extended indigo darken-2">
      <div class="nav-content">
        <router-link :to="{ name: 'Index' }">
          <span class="nav-title">Th Task </span>
        </router-link>
        <a href="" class="btn-floating btn-large halfway-fab pink">
          <router-link :to="{ name: 'AddTasks' }">
            <i class="material-icons">add</i>
          </router-link>
        </a>
         <b-button v-if="user" class="logout" variant="outline-primary" @click="logout">Logout</b-button>
      
      </div>
    </nav>
  </div>
</template>


<script>

import { db,projectAuth } from '@/firebase/config'

export default {
  name: 'Navbar',
  data(){
    return {
      user: null
    }
  },
  created(){
    // let user = firebase.auth().currentUser
    projectAuth.onAuthStateChanged((user) => {
      if(user){
        this.user = user
      } else {
        this.user = null
      }
    })    
  },
  methods: {
    logout(){
      projectAuth.signOut().then(() => {
        this.$router.push({ name: 'Login' })
      })
    }
  }
}
</script>

<style>
.navbar nav{
  padding: 0 20px;
}
.logout{
  float:right;
  margin-top:20px
}
.btn-large a:hover {
    color: #e91e63 ;
    text-decoration: none;
}
</style>

