import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBy07MKI-8uMMokf_w829Zca9OOuDmOcok",
  authDomain: "th-task-55f67.firebaseapp.com",
  projectId: "th-task-55f67",
  storageBucket: "th-task-55f67.appspot.com",
  messagingSenderId: "672704792548",
  appId: "1:672704792548:web:dd5c11b67644065d2369f1",
  measurementId: "G-NMVWCRXF69"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const db = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { db, projectAuth, projectStorage, timestamp,firebase }

