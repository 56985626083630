<template>
  <div class="add-task container z-depth-1">
    <h2 class="center-align indigo-text">Add New Day Task</h2>
    <form @submit.prevent="addTask">
    
       <div>
            <label for="example-datepicker">Choose a date</label>
            <b-form-datepicker id="example-datepicker" v-model="date" class="mb-2"></b-form-datepicker>         
      </div>
        <div class="field title">
        <label for="title">Date task Info:</label>
        <input type="text" name="title" v-model="title">
      </div>
      <div v-for="(tracking, index) in tasks" class="field task" :key="index">
        <label for="task">Task:</label>
        <input type="text" name="task" v-model="tasks[index]">
        <i class="material-icons delete" @click="deleteIng(tracking)">delete</i>
      </div>
      <div class="field add-task">
        <label for="add-task">Add a tracking (press tab to add):</label>
        <b-form-timepicker v-model="time" locale="de" minutes-step="15"></b-form-timepicker>
        <input type="text" name="add-task" @keydown.tab.prevent="addTracking" v-model="another">
      </div>
      <div class="field center-align">
        <p v-if="feedback" class="red-text">{{ feedback }}</p>
        <button class="btn pink">Add date tracking</button>
      </div>
    </form>
  </div>
</template>

<script>
import { db } from '@/firebase/config'
import slugify from 'slugify'

export default {
  name: 'AddTasks',
  data(){
    return{
      title: null,
      tasks: [],
      another: null,
      feedback: null,
      slug: null,
      date: '',
      time: '00:15:00'
    }
  },
  methods: {
    addTask(){
      if(this.date){
        this.feedback = null
        // create a slug
        this.slug = slugify(this.date, {
          replacement: '-',
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true
        })
        //save task to firestore
        db.collection('tasks').add({
          title: this.title,
          tasks: this.tasks,
          slug: this.slug,
          date: this.date
        }).then(() => {
          this.$router.push({ name: 'Index' })
        }).catch(err => {
          console.log(err)
        })



      } else {
        this.feedback = 'You must enter a task date'
      }

    },
    addTracking(){
      if(this.another){
        this.tasks.push(this.another + ' | ' + this.time )
        this.another = null
        this.feedback = null
      } else {
        this.feedback = 'You must enter a value to add another task'
      }
    },
    deleteIng(ing){
      this.tasks = this.tasks.filter(task => {
        return task != ing
      })
    }
  }
}
</script>

<style>
.add-task{
  margin-top: 60px;
  padding: 20px;
  max-width: 500px;
}
.add-task h2{
  font-size: 2em;
  margin: 20px auto;
}
.add-task .field{
  margin: 20px auto;
  position: relative;
}
.add-task .delete{
  position: absolute;
  right: 0;
  bottom: 16px;
  color: #aaa;
  font-size: 1.4em;
  cursor: pointer;
}
</style>

