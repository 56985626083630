<template>
  <div v-if="task" class="edit-smoothie container z-depth-1">
    <h2 class="indigo-text center-align">Edit {{ task.title }} task</h2>
    <form @submit.prevent="editTask">
      <div class="field title">
     
        <input type="text" name="title" v-model="task.date">  
        <label for="example-datepicker">Choose a date</label>
        <b-form-datepicker id="example-datepicker" v-model="task.date" class="mb-2"></b-form-datepicker>    
           <label for="title">task title:</label>
        <input type="text" name="title" v-model="task.title">         
      </div>
      <div v-for="(ing, index) in task.tasks" class="field ingredient" :key="index">
        <label for="ingredient">Tracking:</label>
        <input type="text" name="ingredient" v-model="task.tasks[index]">
        <i class="material-icons delete" @click="deleteIng(ing)">delete</i>
      </div>
      <div class="field add-ingredient">
        <label for="add-task">Add a tracking (press tab to add):</label>
        <b-form-timepicker v-model="time" locale="de" minutes-step="15"></b-form-timepicker>
        <input type="text" name="add-task" @keydown.tab.prevent="addTracking" v-model="another">
      </div>
      <div class="field center-align">
        <p v-if="feedback" class="red-text">{{ feedback }}</p>
        <button class="btn pink">Update task</button>
      </div>
    </form>
  </div>
</template>

<script>
import { db } from '@/firebase/config'
import slugify from 'slugify'

export default {
  name: 'EditTask',
  data(){
    return{
      smoothie: null,
      another: null,
      feedback: null,
      tasks: [],
      task:null,
      date: null,
      time: '00:15:00'
    }
  },
  methods: {
    editTask(){
       if(this.task.date){
        this.feedback = null
        // create a slug
        this.slug = slugify(this.task.date, {
          replacement: '-',
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true
        })
        // update smoothie in firestore
        db.collection('tasks').doc(this.task.id).update({
          title: this.task.title,
          slug: this.slug,
          tasks: this.task.tasks,
          date: this.task.date
        }).then(() => {
          this.$router.push({ name: 'Index' })
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.feedback = 'You must enter a task title'
      }
    
    },
    addTracking(){
      if(this.another){
        this.task.tasks.push(this.another + ' | ' + this.time )
        this.another = null
        this.feedback = null
      } else {
        this.feedback = 'You must enter a value to add another task'
      }
    },
    deleteIng(ing){
      this.task.tasks = this.task.tasks.filter(task => {
        return task != ing
      })
    }
  },
  created(){
    let ref = db.collection('tasks').where('slug', '==', this.$route.params.task_slug)
    ref.get().then(snapshot => {
      snapshot.forEach(doc => {
        this.task = doc.data()
        console.log(  this.task)
        this.task.id = doc.id
      })
    })
  }
}
</script>
 
<style>
.edit-smoothie{
  margin-top: 60px;
  padding: 20px;
  max-width: 500px;
}
.edit-smoothie h2{
  font-size: 2em;
  margin: 20px auto;
}
.edit-smoothie .field{
  margin: 20px auto;
  position: relative;
}
.edit-smoothie .delete{
  position: absolute;
  right: 0;
  bottom: 16px;
  color: #aaa;
  font-size: 1.4em;
  cursor: pointer;
}
</style>
 