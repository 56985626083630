<template>
  <div class="index container">
    <div class="card" v-for="task in tasks" :key="task.id">
      <div class="card-content">
        <i class="material-icons delete" @click="deleteTask(task.id)">delete</i>      
        <h3 class="indigo-text">{{ task.date }}</h3>       
        <h3 class="indigo-text">{{ task.timeCount }}</h3>       
        <b-list-group v-for="(task, index) in task.tasks" :key="index">
        <b-list-group-item>{{ task }}</b-list-group-item>    
      </b-list-group>
      </div>
      <span class="btn-floating btn-large halfway-fab pink">
        <router-link :to="{ name: 'EditTask', params: {task_slug: task.slug}}">
          <i class="material-icons edit">edit</i>
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase/config'

export default {
  name: 'Index',
  data(){
    return{
      tasks: []
    }
  },
  methods: {
    deleteTask(id){
      // delete doc from firestore
      db.collection('tasks').doc(id).delete()
      .then(() => {
        this.tasks = this.tasks.filter(task => {
          return task.id != id
        })
      }).catch(err => {
        console.log(err)
      })
    }
  },
  created(){
    function addTimes (startTime, endTime) {
  var times = [ 0, 0, 0 ]
  var max = times.length

  var a = (startTime || '').split(':')
  var b = (endTime || '').split(':')

  // normalize time values
  for (var i = 0; i < max; i++) {
    a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
    b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
  }

  // store time values
  for (var i = 0; i < max; i++) {
    times[i] = a[i] + b[i]
  }

  var hours = times[0]
  var minutes = times[1]
  var seconds = times[2]

  if (seconds >= 60) {
    var m = (seconds / 60) << 0
    minutes += m
    seconds -= 60 * m
  }

  if (minutes >= 60) {
    var h = (minutes / 60) << 0
    hours += h
    minutes -= 60 * h
  }

  return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
}

    
      
    // fetch data from firestore
    db.collection('tasks').orderBy("date", "desc").limit(9).onSnapshot(snapshot => {

      snapshot.docChanges().forEach(change => {
       
         if (change.type === "added") {
            let task = change.doc.data()
            task.id = change.doc.id              
            let taskHours = task.tasks

            let timeCount = '00:00:00'
               
               taskHours.forEach(x => {                
                   x = x.split('|')[1]
                   
                  timeCount =  addTimes(timeCount, x)
                  
               })
       
               task.timeCount = timeCount
               this.tasks.push(task)
            }
            if (change.type === "removed") {
             this.$router.go()
          
            }
             if (change.type === "modified") {              
                this.$router.go()
            }
         
       
      })
    })
 
  }
}
</script>

<style>
.index{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 60px;
}
.index h2{
  font-size: 1.8em;
  text-align: center;
  margin-top: 0;
}
.index .ingredients{
  margin: 30px auto;
}
.index .ingredients li{
  display: inline-block;
}
.index .delete{
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  color: #aaa;
  font-size: 1.4em;
}
</style>
