<template>
  <div class="login container">
    <form class="card-panel" @submit.prevent="login">
      <h2 class="center deep-purple-text">Login</h2>
      <div class="field">
        <label for="email">Email</label>
        <input id="email" type="email" v-model="email">
      </div>
      <div class="field">
        <label for="password">Password</label>
        <input id="password" type="password" v-model="password">
      </div>
      <p v-if="feedback" class="red-text center">{{ feedback }}</p>
      <div class="field center">
        <button class="btn ">Login</button>
      </div>
       <p>      or Sign In with Google <br>
      <button @click="socialLogin" class="social-button">
        <img alt="Google Logo" src="../../assets/google-logo.png">
      </button>
    </p>
    </form>
  </div>
</template>

<script>
import { projectAuth } from '@/firebase/config'
import firebase from "firebase/app";

export default {
  name: 'Login',
  data(){
    return{
      email: null,
      password: null,
      feedback: null
    }
  },
  methods: {
    login(){
      if(this.email && this.password){
        this.feedback = null
        projectAuth.signInWithEmailAndPassword(this.email, this.password)
        .then(user => {
          //console.log(user)
          this.$router.push({ name: 'Index' })
        }).catch(err => {
          this.feedback = err.message
        })
      } else {
        this.feedback = 'Please fill in both fields'
      }
    },
      socialLogin() {
       
        const provider = new firebase.auth.GoogleAuthProvider();
        projectAuth.signInWithPopup(provider).then((result) => {
          this.$router.push({ name: 'Index' })
        }).catch((err) => {
          alert('Oops. ' + err.message)
        });
      }    
  }
}
</script>

<style>
.login{
  max-width: 400px;
  margin-top: 60px;
}
.login h2{
  font-size: 2.4em;
}
.login .field{
  margin-bottom: 16px;
}
 .social-button {
    width: 75px;
    background: white;
    padding: 10px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    outline: 0;
    border: 0;
  }
  .social-button:active {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  }
  .social-button img {
    width: 100%;
  }
  button:focus {
    outline: none;
    background-color: #c9c9c9!important;
}
</style>
