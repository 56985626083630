import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/Index'
import AddTasks from '@/components/AddTasks'
import EditTask from '@/components/EditTask'
import OneOverview from '@/components/OneOverview'
import Signup from '@/components/auth/Signup'
import Login from '@/components/auth/Login'
import { projectAuth } from '@/firebase/config'
import firebase from "firebase/app";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      requiresAuth: true
    }
  }, 
  {
    path: '/one',
    name: 'OneOverview',
    component: OneOverview,
    meta: {
      requiresAuth: true
    }
  } ,
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/add-tasks',
      name: 'AddTasks',
      component: AddTasks,
    meta: {
      requiresAuth: true
    }
    },
    {
      path: '/edit-task/:task_slug',
      name: 'EditTask',
      component: EditTask,
      meta: {
        requiresAuth: true
      }
    }
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router guards
router.beforeEach((to, from, next) => {
  // check to see if route has auth guard

 // console.log(to);
 // console.log(to.matched.some(rec => rec.meta.requiresAuth));
  if(to.matched.some(rec => rec.meta.requiresAuth)){
    // check auth state of user
    
    projectAuth.onAuthStateChanged((user) => {     
  // Check if user meets role criteria.
      if(user){
        next()
      } else {
        next({
          name: 'Login'
        })
      }
    }) 

  } else {
    // if route is not guarded by auth, proceed
    next()
  }
})

export default router
