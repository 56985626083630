<template>
  <div class="signup container">
    <form class="card-panel" @submit.prevent="signup">
      <h2 class="center deep-purple-text">Signup</h2>
      <div class="field">
        <label for="email">Email</label>
        <input id="email" type="email" v-model="email">
      </div>
      <div class="field">
        <label for="password">Password</label>
        <input id="password" type="password" v-model="password">
      </div>     
      <p v-if="feedback" class="red-text center">{{ feedback }}</p>
      <div class="field center">
        <button class="btn ">Signup</button>
      </div>
    </form>
  </div>
</template>

<script>
import { db,projectAuth } from '@/firebase/config'
import slugify from 'slugify'
//import { projectAuth } from '@/firebase/config'

export default {
  name: 'Signup',
  data(){
    return{
      email: null,
      password: null,
      alias: null,
      feedback: null,
      slug: null
    }
  },
  methods: {
    signup(){
      if(this.email && this.password){
        this.feedback = null            
       
          // this alias does not yet exists in the db
            projectAuth.createUserWithEmailAndPassword(this.email, this.password)
            .then(user => {
             
            }).then(() => {
              this.$router.push({ name: 'Index' })
            })
            .catch(err => {
              this.feedback = err.message
            })
         
     
      } else {
        this.feedback = 'Please fill in all fields'
      }
    }
  }
}
</script>

<style>
.signup{
  max-width: 400px;
  margin-top: 60px;
}
.signup h2{
  font-size: 2.4em;
}
.signup .field{
  margin-bottom: 16px;
}
</style>

